import { graphql } from 'gatsby'
import React from 'react'

import { capitalize } from '../../lib/string'

import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const query = graphql`
  query AccordTemplateQuery(
    $accordId: String!,
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      accord: accordById(id: $accordId) {
        ...Accord
        family: familyByFamily {
          ...Family
        }
        fragrances: fragrancesList(
          filter: { gender: { equalTo: $genderId }}
        ) {
          ...Fragrance
        }
        notes: notesByAccordList(
          filter: { genders: { contains: [ $genderId ] }},
        ) {
          ...Note
        }
      }
    }
  }
`

export default ({ data }) => {
  const { accord } = data.results
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = `${accord.name} ${entity.singular} Accord`
  const title = `${accord.name} Accord - ${accord.family.name} Family`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Notes',
      slug: links.notes,
    },
    {
      name: accord.family.name,
      slug: accord.family.slug,
    },
    {
      name: capitalize(accord.id) + ' Accord',
      slug: accord.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: accord.about,
    image: imageMain.src,
    keywords,
    name: title,
    thing: accord,
    things: accord.notes,
    slug: accord.slug,
  }

  return (
    <Layout>
      <SEO
        description={accord.about}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <Image
        alt={accord.name}
        credit={accord.image}
        fixed={imageMain}
      />

      <Paragraph>
        Family: {' '}
        <Link href={accord.family.slug}>
          {accord.family.name}
        </Link>
      </Paragraph>

      {accord.about && (
        <Paragraph>
          {accord.about}
        </Paragraph>
      )}

      <Heading level={2}>{accord.name} Notes</Heading>
      {(accord.notes.length > 0) ? (
        <List>
          {accord.notes.map(note => (
            <ListItem key={note.id}>
              <Link href={note.slug}>
                {note.name} Note
              </Link>
            </ListItem>
          ))}
        </List>
      ) : (
        <Paragraph>No notes found.</Paragraph>
      )}

      <Heading level={2}>{accord.name} {entity.plural}</Heading>
      <FragrancesList fragrances={accord.fragrances} />

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
